import Loader from '@components/common/Loader';
import GenerateTable from '@components/dashboard/GenerateTable';
import QueryToolbar from '@components/dashboard/toolbar';
// import Chart from '@components/reports/chart';
import KPIpanel from '@components/reports/KPIcard';
import ReportFilter from '@components/reports/ReportFilter';
import StackedBar from '@components/tremor/stackedBar';
import { getAxiosClient } from '@functions/api/axiosInstance';
import { KPIdataType } from '@interfaces/api/reports/detailedResponse';
import { useAppSelector } from '@store/store';
// import { createFileRoute } from '@tanstack/react-router'
import { useEffect, useState } from 'react';


type ResponseDataType = {
    logo: string,
    orgName: string,
    reportName: string,
    tableData: {
        columns: {
            title: string,
            dataIndex: string,
            key: string
        }[],
        rows: {
            [key: string]: string
        }[]
    },
    kpiData: KPIdataType[],
    graphData: { x: (string | number)[], y: (string | number)[] },
    filterArray: {
        filterName: string,
        filterOptions: {
            label: string,
            value: string
        }[],
        default: string
    }[]
}


const TemplateReport = ({ reportName, companyName }) => {

    const [fetchedData, setFetchedData] = useState<ResponseDataType | null>(null)
    const [tableData, setTableData] = useState({ columns: [], data: [] })

    const filters = useAppSelector((state) => state.filterStore.filters)

    const [reportLoading, setReportLoading] = useState(true)
    const [filterLoading, setFilterLoading] = useState(true)


    useEffect(() => {
        setReportLoading(true)
        setFilterLoading(true)
    }, [])

    useEffect(() => {
        setFilterLoading(true)
        const fetchData = async () => {
            // Fetch data here
            const _axios = await getAxiosClient()
            const res = await _axios.post(`/api/v2/reports/${companyName}/${reportName}`, {
                parameters: {
                    filters
                }
            })
            setFetchedData(res.data)
            console.log(res.data);
            setReportLoading(false)
            setFilterLoading(false)

            const data = res.data?.tableData
            if (data?.columns && data?.rows) {
                setTableData({ columns: res.data.tableData.columns, data: res.data.tableData.rows })
            }
        }
        fetchData()
    }, [companyName, reportName, filters])

    return !reportLoading ? (
        <div className="mx-auto w-11/12 py-5 md:overflow-y-hidden grid overflow-y-hidden">
            <div className="pb-5 pt-2 text-center md:text-left w-full flex">
                <img src={fetchedData?.logo} alt="logo" className='w-24 mx-2' />
                <h1 className="text-3xl px-2 dark:text-fireai-blue">
                    {fetchedData?.orgName}
                </h1>
            </div>

            <div className='w-full text-left font-medium bg-fireai-grey dark:bg-[#1D1D1D] flex flex-col md:flex-row justify-normal md:justify-between py-2 rounded-xl'>
                <h3 className='text-xl px-5 font-medium dark:text-fireai-blue'>{fetchedData?.reportName} Report</h3>
                <div className='flex gap-5 items-center mx-5'>
                    {fetchedData?.filterArray?.map((filter, index) => {
                        return (
                            <div key={index} className="flex gap-2 items-center">
                                <span className='font-light'>{filter.filterName}</span>
                                <ReportFilter
                                    isLoading={false}
                                    defaultOption={filter.default}
                                    filterOptions={filter.filterOptions}
                                    filterName={filter.filterName}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>

            {
                !filterLoading ? (fetchedData && (
                    <>
                        <KPIpanel kpi={fetchedData?.kpiData} />
                        <div className="grid grid-cols-12 gap-3 grid-flow-dense">
                            <div className="col-span-12">

                                <StackedBar graphdata={fetchedData.graphData} />
                                <div className="order-2 border-2 border-[#CACACA] rounded-xl">
                                    <div className='py-2 border-b-2 dark:bg-[#1d1d1d] rounded-t-lg flex flex-row justify-between'>
                                        <div className='flex flex-col justify-center'>
                                            <span className='ml-2 font-medium'>{fetchedData.reportName} Table</span>
                                        </div>
                                        <div className='flex flex-col justify-center'>
                                            <QueryToolbar data={tableData.data} reportName={fetchedData?.reportName} />
                                        </div>
                                    </div>
                                    <GenerateTable response={tableData} />
                                </div>
                            </div>
                        </div>
                    </>)) : (<Loader />)
            }
        </div>
    ) : (<Loader />);
}

export default TemplateReport;