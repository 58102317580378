import React, { useState, useMemo, useEffect, useCallback } from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

interface DataItem {
  [key: string]: number | string;
}

interface Column {
  key: string;
  dataIndex: string;
  title: string;
}

// interface ApiResponse {
//   columns: Column[];
//   data: DataItem[];
// }

// interface QueryVisualizerProps {
//   tableData: ApiResponse;
// }

const MAX_DATA_POINTS = 1000; // Maximum number of data points to process

export default function QueryVisualizer({ tableData }: any) {
  const [selectedColumn, setSelectedColumn] = useState<string>("");
  const [topPercentage, setTopPercentage] = useState<number>(30);
  const [chartType, setChartType] = useState<"donut" | "bar">("donut");
  const [barCount, setBarCount] = useState<number>(10);
  const [sortAscending, setSortAscending] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    if (tableData && tableData.columns.length > 1) {
      setSelectedColumn(tableData.columns[1].dataIndex);
    }
  }, [tableData]);

  const handleColumnChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      setSelectedColumn(e.target.value);
      setErrorMessage("");
    },
    []
  );

  const handleTopPercentageChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setTopPercentage(Number(e.target.value));
    },
    []
  );

  const handleChartTypeChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      setChartType(e.target.value as "donut" | "bar");
    },
    []
  );

  const handleBarCountChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setBarCount(Number(e.target.value));
    },
    []
  );

  const toggleSortOrder = useCallback(() => {
    setSortAscending((prev) => !prev);
  }, []);

  const getChartData = useMemo(() => {
    if (!tableData || !selectedColumn) return { labels: [], series: [] };

    const isNumeric = (value: any) =>
      !isNaN(parseFloat(value)) && isFinite(value);

    let numericData = tableData.data.filter((item) =>
      isNumeric(item[selectedColumn])
    );

    if (numericData.length === 0) {
      setErrorMessage(
        "Can't generate graph for this data. Selected column contains non-numeric values."
      );
      return { labels: [], series: [] };
    }

    if (numericData.length > MAX_DATA_POINTS) {
      setErrorMessage(
        `Dataset is too large. Showing top ${MAX_DATA_POINTS} items.`
      );
      numericData = numericData.slice(0, MAX_DATA_POINTS);
    }

    const sortedData = [...numericData].sort((a, b) =>
      sortAscending
        ? Number(a[selectedColumn]) - Number(b[selectedColumn])
        : Number(b[selectedColumn]) - Number(a[selectedColumn])
    );

    if (chartType === "bar") {
      const topItems = sortedData.slice(0, barCount);
      const labels = topItems.map((obj) =>
        String(obj[tableData.columns[0].dataIndex])
      );
      const series = [
        {
          name: tableData.columns.find(
            (col) => col.dataIndex === selectedColumn
          )?.title,
          data: topItems.map((obj) => Number(obj[selectedColumn])),
        },
      ];
      return { labels, series };
    } else {
      const total = sortedData.reduce(
        (acc, obj) => acc + Number(obj[selectedColumn]),
        0
      );
      const threshold = total * (topPercentage / 100);

      let runningSum = 0;
      const topItems: DataItem[] = [];
      const others: DataItem[] = [];

      for (const item of sortedData) {
        if (runningSum < threshold) {
          topItems.push(item);
          runningSum += Number(item[selectedColumn]);
        } else {
          others.push(item);
        }
      }

      const labels = topItems.map((obj) =>
        String(obj[tableData.columns[0].dataIndex])
      );
      const series = topItems.map((obj) => Number(obj[selectedColumn]));

      if (others.length > 0) {
        const othersValue = others.reduce(
          (acc, obj) => acc + Number(obj[selectedColumn]),
          0
        );
        labels.push("Others");
        series.push(othersValue);
      }

      return { labels, series };
    }
  }, [
    tableData,
    selectedColumn,
    topPercentage,
    chartType,
    barCount,
    sortAscending,
  ]);

  const { labels, series } = getChartData;

  const commonOptions: ApexOptions = {
    labels,
    chart: {
      fontFamily: "Inter, sans-serif",
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
      background: "#ffffff",
    },
    // theme: {
    //   mode: "light",
    // },
    legend: {
      position: "bottom",
      fontFamily: "Inter, sans-serif",
    },
    dataLabels: {
      enabled: true,
      formatter: (val: number) => Math.round(val).toLocaleString(),
      style: {
        fontSize: "12px",
        fontFamily: "Inter, sans-serif",
        // colors: ["#000000"],
      },
    },
    tooltip: {
      y: {
        formatter: (val: number) => Math.round(val).toLocaleString(),
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 300,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  const barOptions: ApexOptions = {
    ...commonOptions,
    chart: {
      ...commonOptions.chart,
      type: "bar",
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: "top",
        },
      },
    },
  };

  const donutOptions: ApexOptions = {
    ...commonOptions,
    chart: {
      ...commonOptions.chart,
      type: "donut",
    },
    plotOptions: {
      pie: {
        donut: {
          size: "65%",
          background: "transparent",
          labels: {
            show: true,
            name: {
              show: true,
              fontFamily: "Inter, sans-serif",
              color: "#000000",
            },
            value: {
              show: true,
              fontFamily: "Inter, sans-serif",
              color: "#000000",
              formatter: (w: any) => {
                const total = w.globals.seriesTotals.reduce(
                  (a: number, b: number) => a + b,
                  0
                );
                return Math.round(total).toLocaleString();
              },
            },
            total: {
              show: true,
              label: "Total",
              fontFamily: "Inter, sans-serif",
              color: "#000000",
              formatter: (w: any) => {
                const total = w.globals.seriesTotals.reduce(
                  (a: number, b: number) => a + b,
                  0
                );
                return Math.round(total).toLocaleString();
              },
            },
          },
        },
      },
    },
  };

  if (!tableData) return <div>No tableData available</div>;

  return (
    <div className="p-6 min-h-screen flex flex-col items-center justify-center space-y-6 transition-all duration-300 ease-in-out bg-white text-black">
      <div className="w-full max-w-md space-y-4">
        <div className="flex flex-col space-y-2">
          <label className="font-semibold text-blue-600">
            Select Column to Display:
          </label>
          <select
            value={selectedColumn}
            onChange={handleColumnChange}
            className="p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200 bg-white text-black border-gray-300"
          >
            {tableData.columns.slice(1).map((col) => (
              <option key={col.key} value={col.dataIndex}>
                {col.title}
              </option>
            ))}
          </select>
        </div>
        <div className="flex flex-col space-y-2">
          <label className="font-semibold text-blue-600">Chart Type:</label>
          <select
            value={chartType}
            onChange={handleChartTypeChange}
            className="p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200 bg-white text-black border-gray-300"
          >
            <option value="donut">Donut</option>
            <option value="bar">Bar</option>
          </select>
        </div>
        {chartType === "donut" ? (
          <div className="flex flex-col space-y-2">
            <label className="font-semibold text-blue-600">
              Top Percentage: {topPercentage}%
            </label>
            <input
              type="range"
              min="5"
              max="100"
              value={topPercentage}
              onChange={handleTopPercentageChange}
              className="w-full h-2 bg-blue-600 rounded-lg appearance-none cursor-pointer"
            />
          </div>
        ) : (
          <div className="flex flex-col space-y-2">
            <label className="font-semibold text-blue-600">
              Number of Bars: {barCount}
            </label>
            <input
              type="range"
              min="3"
              max="20"
              value={barCount}
              onChange={handleBarCountChange}
              className="w-full h-2 bg-blue-600 rounded-lg appearance-none cursor-pointer"
            />
          </div>
        )}
        <div className="flex flex-col space-y-2">
          <button
            onClick={toggleSortOrder}
            className="p-2 rounded-md hover:bg-opacity-80 transition-all bg-blue-600 text-white"
          >
            Toggle Sort Order: {sortAscending ? "Ascending" : "Descending"}
          </button>
        </div>
      </div>
      <div className="w-full max-w-3xl p-6 rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300 bg-white">
        <ReactApexChart
          options={chartType === "bar" ? barOptions : donutOptions}
          series={chartType === "bar" ? series : (series as number[])}
          type={chartType}
          height={400}
        />
      </div>
    </div>
  );
}
